<template>
  <div class="index">
    <img class="index-head-img" src="@/assets/img/head.png" />
    <div class="index-main-title">助校通</div>
    <div class="index-block">
      <div class="index-list" @click="toReadme('student')">
        <div class="index-list-title">招生</div>
        <div class="index-list-body">
          <div class="index-list-li">系统搭建</div>
          <div class="index-list-li">一、线索管理</div>
          <div class="index-list-li">二、回访跟进</div>
          <div class="index-list-li">三、缴费申请</div>
        </div>
        <div class="index-to-see"><span>查看 ></span></div>
      </div>
      <div class="index-list" @click="toReadme('education')">
        <div class="index-list-title">教务</div>
        <div class="index-list-body">
          <div class="index-list-li">一、学员管理</div>
          <div class="index-list-li">二、班级管理</div>
          <div class="index-list-li">三、学员关怀</div>
          <div class="index-list-li">四、报考成绩</div>
        </div>
        <div class="index-to-see"><span>查看 ></span></div>
      </div>
      <div class="index-list" @click="toReadme('finance')">
        <div class="index-list-title">财务</div>
        <div class="index-list-body">
          <div class="index-list-li">一、财务流水</div>
          <div class="index-list-li">二、办理缴费</div>
          <div class="index-list-li">三、办理退费</div>
          <div class="index-list-li">四、数据分析</div>
        </div>
        <div class="index-to-see"><span>查看 ></span></div>
      </div>
    </div>
    <div class="index-main-title">其他产品</div>
    <div class="index-block">
      <div class="index-list" @click="toReadme('call')">
        <div class="index-list-title">飞牛云外呼</div>
        <div class="index-list-body">
          <div class="index-list-li">一、线索管理</div>
          <div class="index-list-li">二、回访跟进</div>
          <div class="index-list-li">二、线索调配</div>
          <div class="index-list-li">二、用户管理</div>
        </div>
        <div class="index-to-see"><span>查看 ></span></div>
      </div>
      <div class="index-list" @click="toReadme('scrm')">
        <div class="index-list-title">助校通scrm</div>
        <div class="index-list-body">
          <div class="index-list-li">一、系统首页</div>
          <div class="index-list-li">二、引流获客</div>
          <div class="index-list-li">二、客户转化</div>
          <div class="index-list-li">二、客户管理</div>
        </div>
        <div class="index-to-see"><span>查看 ></span></div>
      </div>
      <div class="index-list" @click="toReadme('card')">
        <div class="index-list-title">裂变名片小程序</div>
        <div class="index-list-body">
          <div class="index-list-li">一、功能简介</div>
          <div class="index-list-li">二、后台使用</div>
        </div>
        <div class="index-to-see"><span>查看 ></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Index",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapMutations(["typeSwitch"]),
    toReadme(type) {
      this.typeSwitch(type);
      this.$router.push("/home");
    },
  },
};
</script>
<style scoped>
.index {
  padding: 2.5vw;
}
.index-head-img {
  width: 100%;
}
.index-main-title {
  position: relative;
  color: #606266;
  font-size: 20px;
  padding-top: 2.5vw;
  border-radius: 30px;
  padding-left: 11px;
  font-weight: bold;
}

.index-main-title::before {
  position: absolute;
  left: 0;
  bottom: 3px;
  margin: auto;
  content: "";
  height: 18px;
  width: 3px;
  background: #1296db;
}
.index-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.index-list {
  display: flex;
  flex-direction: column;
  height: 228px;
  width: 23%;
  margin-right: 2.665%;
  background: white;
  box-shadow: 0 0 10px 0 #ccc;
  box-sizing: border-box;
  padding: 0 15px;
  border-radius: 5px;
  color: #68747f;
  transition: all 0.3s;
  margin-top: 2.5vw;
}
.index-list-body {
  flex: 1;
}
.index-list:hover {
  transform: scale(1.05);
}
.index-list:nth-child(4n) {
  margin-right: 0;
}
.index-list-title {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 0;
  border-bottom: 1px solid #e0e4ea;
  padding-left: 18px;
}
.index-list-title::before {
  position: absolute;
  content: "";
  left: 0;
  height: 18px;
  width: 5px;
  border-radius: 5px;
  top: 17px;
  background: #409eff;
}
.index-list-li {
  position: relative;
  padding: 8px 0;
  font-size: 14px;
  padding-left: 20px;
}
.index-list-li::before {
  content: "●";
  position: absolute;
  left: 0;
  top: 10px;
}
.index-to-see {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
}
.index-to-see > span:hover {
  color: #409eff;
  cursor: pointer;
}
@media screen and (max-width: 950px) {
  .index-list {
    width: 48%;
    margin-right: 4%;
  }
  .index-list:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 500px) {
  .index-list {
    width: 100%;
    margin-right: 0;
  }
}
</style>
